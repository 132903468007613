import React, { useState } from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index';
import RichText from '../shared/RichText';
import { useGlobalDictionaryQuery } from '../../hooks/index';

interface Props {
  description: {
    json: string;
  };
  specs: {
    json: string;
  };
  productTag: string;
}

export default function ProductFormDescription(props: Props) {
  const { description, specs, productTag } = props;

  const dictionary = useGlobalDictionaryQuery();

  const content = {
    Description: description,
    Details: specs,
    Delivery:
      productTag === 'Second chance' ||
      productTag === 'Showroom Model' ||
      productTag === 'Tweedekans'
        ? dictionary.productDeliveryReturnsInfoForSecondChance
        : dictionary?.productDeliveryReturnsInfo,
  };

  const [show, setShow] = useState('Description');

  const getCorrectTranslation = title => {
    switch (title) {
      case 'Description':
        return dictionary.description;
      case 'Details':
        return dictionary.details;
      case 'Delivery':
        return dictionary.delivery;
      default:
        'Details';
    }
  };

  return (
    <Wrapper>
      <Header>
        {Object.keys(content).map(title => (
          <HeaderItemWrapper
            key={title}
            isCentered={title === 'Details' ? true : false}
            show={show}
            title={title}
          >
            <p onClick={() => setShow(title)}>{getCorrectTranslation(title)}</p>
          </HeaderItemWrapper>
        ))}
      </Header>
      <Content>
        <RichText json={content[show].json} />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;

  ${desktopBreakpoint} {
  }
`;

const HeaderItemWrapper = styled.div`
  width: auto;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  padding: ${mobileVW(29)} ${mobileVW(19)} ${mobileVW(20)} 0;
  text-transform: uppercase;
  font-size: ${mobileVW(15)};
  ${({ title, show }) => (title === show ? `color: ${colors.black};` : null)}
  ${({ isCentered }) => (isCentered ? 'justify-content: center;' : null)}

  p {
    cursor: pointer;
    white-space: nowrap;
  }

  ${desktopBreakpoint} {
    width: auto;
    margin-right: ${desktopVW(30)};
    padding: ${desktopVW(28)} 0 ${desktopVW(15)} 0;
    font-size: ${desktopVW(12)};
  }
`;

const Content = styled.div`
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(14.5)};
  line-height: ${mobileVW(26)};
  padding-bottom: ${mobileVW(30)};
  color: ${colors.black};

  ul {
    text-align: left;
    transform: translateX(1.4em);
  }


  ${desktopBreakpoint}{
    // height: ${desktopVW(178)};
    font-size: ${desktopVW(11.5)};
    line-height: ${desktopVW(19)};
    margin-bottom: ${desktopVW(20)};
    padding-bottom: 0;
  }
`;
